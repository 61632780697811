@import "../../lib/swiper/swiper-bundle.min.css";
@import "./reset";
@import "./variable";
@import "./base";
@import "./share";
@import "./layout/loading";
@import "./layout/header";
@import "./layout/footer";
@import "./components/lightbox";
@import "./components/maintitle";
@import "./components/breadcrumbs";
@import "./components/button";


.tracker {
    width: 0;
    height: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 49;

    @media screen and (max-width: 768px) {
        display: none;
    }

    &:before {
        content: "";
        width: 60 / $setFs + rem;
        height: 60 / $setFs + rem;
        border-radius: 60 / $setFs + rem;
        pointer-events: none;
        background: $color;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: scale(0) translate(-50%, -50%);
        transition: all 0.2s ease-out;
        opacity: .5;
    }

    &.hover {

        &:before {
            transform: scale(1) translate(-50%, -50%);
        }

        .tracker_center {
            transform: scale(1) translate(-50%, -50%);
            animation: tracker_center 3s linear infinite;
        }
    }

    &.color_0 {
        &::before {
            background: $color3;
        }
    }

    &.color_1 {
        &::before {
            background: $color4;
        }
    }

    &.color_2 {
        &::before {
            background: $color5;
        }
    }

    &.color_3 {
        &::before {
            background: $color6;
        }
    }

    &.color_4 {
        &::before {
            background: $color7;
        }
    }

    &.color_5 {
        &::before {
            background: $color8;
        }
    }
}

@keyframes tracker_center {
    100% {
        transform: scale(1) translate(-50%, -50%) rotate(360deg);
    }
}

.tracker_center {
    width: 0;
    height: 0;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(0) translate(-50%, -50%);
    transition: transform 0.1s ease-out;

    &::before,
    &::after {
        content: '';
        width: 20 / $setFs + rem;
        height: 1px;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &::after {
        transform: translate(-50%, -50%) rotate(90deg);
    }
}

.header.active {
    &~.backtotop {
        opacity: .4;
        visibility: visible;

        &:hover {
            opacity: 1;
            transition: opacity .3s ease-out;
        }
    }
}

.backtotop {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: $color;
    position: fixed;
    right: 30px;
    bottom: 90px;
    z-index: 50;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s ease-out;
}

.backtotop_link {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include acc;
}

.backtotop_icon {
    width: 17px;
}

@keyframes backtotop {
    0% {
        background: $color3;
    }

    0% {
        background: $color4;
    }

    0% {
        background: $color5;
    }

    0% {
        background: $color6;
    }

    0% {
        background: $color7;
    }

    100% {
        background: $color8;
    }
}