.func_list {
    display: flex;
    position: relative;
    padding: 0 20 / $setFs + rem;
    margin-right: 40 / $setFs + rem;

    @media screen and (max-width: 950px) {
        margin-right: -20 / $setFs + rem;
    }

    &::before {
        content: '';
        width: 1 / $setFs + rem;
        height: 15 / $setFs + rem;
        background: #aaaaaa;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-55%);

        @media screen and (max-width: 950px) {
            display: none;
        }
    }
}

.func_li {
    padding: 0 20 / $setFs + rem;
}

.func_link {
    font-size: (20 + 5) / $setFs + rem;
    font-family: $font1;
    font-weight: bold;
    white-space: nowrap;
    bottom: -2 / $setFs + rem;
    @include acc;
}

.icon_text {
    width: 24 / $setFs + rem;
    position: relative;
    bottom: -2 / $setFs + rem;
    transition: width .2s ease-out;
}

.header_cate_list {
    display: flex;
    justify-content: flex-end;
    margin-right: -29 / $setFs + rem;
    margin-left: -29 / $setFs + rem;

    @media screen and (max-width: 950px) {
        margin-right: 0;
        margin-left: 0;
    }

    .header_cate_list {
        justify-content: center;
        padding: 30 / $setFs + rem 0 20 / $setFs + rem;
        flex-wrap: wrap;

        @media screen and (max-width: 950px) {
            width: 100%;
            box-sizing: border-box;
        }

        .header_cate_li {

            // @media screen and (min-width: 951px) {
            &:before {
                content: '';
                width: 1 / $setFs + rem;
                height: calc(100% - 1.6rem);
                background: #e5e5e5;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }

            &:first-child {
                &:before {
                    display: none;
                }
            }

            // }
        }

        .header_cate_link {
            color: $color;
            font-size: (24 + 5) / $setFs + rem;
            font-family: $font1;
            font-weight: 700;

            @media screen and (max-width: 950px) {
                font-size: 22 / $setFs + rem;
                text-align: center;
                padding: 7 / $setFs + rem 15 / $setFs + rem 6 / $setFs + rem;
            }

            @media screen and (max-width: 480px) {
                font-size: 20 / $setFs + rem;
            }
        }
    }

    .button_link {

        @media screen and (max-width: 950px) {
            font-size: 17 / $setFs + rem;
        }
    }
}

.header_cate_li {
    position: relative;

    // @media screen and (min-width: 951px) {

    &:hover,
    &.show {
        .subnav_box {
            opacity: 1;
            visibility: visible;

            @media screen and (max-width: 950px) {
                //     transform: none;
                top: 7.15rem;
            }
        }
    }


    &:nth-child(5n+1) {
        &.show {
            &>.header_cate_link {
                color: $color4;
            }
        }

        &>.header_cate_link {

            &:hover,
            &:focus {
                color: $color4;
            }
        }
    }

    &:nth-child(5n+2) {
        &.show {
            &>.header_cate_link {
                color: $color3;
            }
        }

        &>.header_cate_link {

            &:hover,
            &:focus {
                color: $color3;
            }
        }
    }

    &:nth-child(5n+3) {
        &.show {
            &>.header_cate_link {
                color: $color8;
            }
        }

        &>.header_cate_link {

            &:hover,
            &:focus {
                color: $color8;
            }
        }
    }

    &:nth-child(5n+4) {
        &.show {
            &>.header_cate_link {
                color: $color6;
            }
        }

        &>.header_cate_link {

            &:hover,
            &:focus {
                color: $color6;
            }
        }
    }

    &:nth-child(5n+5) {
        &.show {
            &>.header_cate_link {
                color: $color7;
            }
        }

        &>.header_cate_link {

            &:hover,
            &:focus {
                color: $color7;
            }
        }
    }

    // }
}

.header_cate_link {
    font-size: (18 + 5) / $setFs + rem;
    font-family: $font1;
    font-weight: bold;
    padding: 14 / $setFs + rem 29 / $setFs + rem 13 / $setFs + rem;
    bottom: -2 / $setFs + rem;
    @include acc;

    @media screen and (max-width: 950px) {
        padding: 14 / $setFs + rem 15 / $setFs + rem 13 / $setFs + rem;
    }
}

.left_border {
    position: relative;

    &::before {
        content: '';
        width: 1 / $setFs + rem;
        height: 15 / $setFs + rem;
        background: #aaaaaa;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        @media screen and (max-width: 950px) {
            display: none;
        }
    }
}