.footer {
    color: #fff;
    font-weight: 100;
    background: $color;
    border-top: 1 / $setFs + rem solid #333134;
    padding: 60 / $setFs + rem 0 95 / $setFs + rem;
    position: relative;
    z-index: 1;

    // .container {
    //     max-width: 1680 / $setFs + rem;
    // }

    .accesskey {
        &::after {
            border-color: #fff;
        }
    }
}

.footer_row {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 950px) {
        display: block;
    }
}

.footer_left {
    flex: 0 0 188 / $setFs + rem;
}

.footer_middle {
    flex: 1;
    padding-left: 102 / $setFs + rem;

    @media screen and (max-width: 950px) {
        padding-top: 60 / $setFs + rem;
        padding-left: 0;
    }

    .footer_middle {
        padding-left: 0;
    }
}

.footer_right {
    display: flex;
    align-items: flex-end;

    @media screen and (max-width: 950px) {
        padding-top: 30 / $setFs + rem;
        padding-left: 0;
    }

    @media screen and (max-width: 480px) {
        justify-content: center;
    }
}

.footer_mainlogo {
    @media screen and (max-width: 950px) {
        width: 224 / $setFs + rem;
        margin: 0 auto;
    }
}

.footer_mainlogo_link {
    @include acc;

    &::after {
        border-color: #fff;
    }
}

.info_row {
    margin-bottom: 38 / $setFs + rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.info_title {
    font-size: 17 / $setFs + rem;
    margin-bottom: 10 / $setFs + rem;
}


.footer_social_list {
    display: flex;
    margin-left: -5 / $setFs + rem;
    margin-top: 30 / $setFs + rem;

    @media screen and (max-width: 950px) {
        justify-content: center;
        margin-left: 0;
        margin-bottom: 20 / $setFs + rem;
    }
}

.footer_social_li {
    padding: 0 5 / $setFs + rem;

    @media screen and (max-width: 480px) {
        padding: 0 7 / $setFs + rem;
    }
}

.footer_social_link {
    width: 50 / $setFs + rem;
    height: 50 / $setFs + rem;
    border-radius: 50 / $setFs + rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);

    @media screen and (max-width: 480px) {
        width: 64 / $setFs + rem;
        height: 64 / $setFs + rem;
    }

    &:focus,
    &:hover {
        background: #fff;
        color: $color;
    }

    .fa-youtube {
        font-size: 18 / $setFs + rem;

        @media screen and (max-width: 480px) {
            font-size: 24 / $setFs + rem;
        }
    }

    .fa-facebook-f {
        font-size: 18 / $setFs + rem;

        @media screen and (max-width: 480px) {
            font-size: 24 / $setFs + rem;
        }
    }

    .fa-instagram {
        font-size: 19 / $setFs + rem;

        @media screen and (max-width: 480px) {
            font-size: 25 / $setFs + rem;
        }
    }
}

.footer_info_row {
    margin-bottom: 25 / $setFs + rem;

    @media screen and (max-width: 480px) {
        text-align: center;
        margin-top: 25 / $setFs + rem;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.footer_title {
    font-size: 20 / $setFs + rem;
    font-family: $font1;
    margin-bottom: 10 / $setFs + rem;

    @media screen and (max-width: 480px) {
        font-size: 24 / $setFs + rem;
    }
}

.footer_links {
    display: flex;
    margin-left: -18 / $setFs + rem;
    margin-right: -18 / $setFs + rem;
    margin-bottom: 10 / $setFs + rem;

    @media screen and (max-width: 480px) {
        display: block;
        margin: 0 auto 10 / $setFs + rem;
    }
}

.footer_li {
    font-size: 18 / $setFs + rem;
    padding: 0 18 / $setFs + rem;

    @media screen and (max-width: 480px) {
        font-size: 20 / $setFs + rem;
        line-height: 2;
    }
}

.footer_link {
    @include acc;

    &::after {
        border-color: #fff;
    }
}

.footer_acc {
    width: 88 / $setFs + rem;
    margin-left: auto;
    // margin-bottom: 50 / $setFs + rem;
    margin-bottom: 10 / $setFs + rem;

    @media screen and (max-width: 950px) {
        margin-bottom: 20 / $setFs + rem;
        margin-left: 0;
    }

    @media screen and (max-width: 480px) {
        margin: 0 auto 20 / $setFs + rem;
    }
}

.acc_link {
    @include acc;

    &::after {
        border-color: #fff;
    }
}

.copyright {
    text-align: right;
    font-size: 15 / $setFs + rem;
    line-height: 2;
    padding-bottom: 5 / $setFs + rem;

    @media screen and (max-width: 950px) {
        text-align: left;
    }

    @media screen and (max-width: 480px) {
        text-align: center;
    }
}

.epaper_row {
    display: flex;
    margin-bottom: 40 / $setFs + rem;

    @media screen and (max-width: 950px) {
        flex-wrap: wrap;
    }

    @media screen and (max-width: 480px) {
        justify-content: center;
    }
}

.epaper_left {
    padding: 3 / $setFs + rem 15 / $setFs + rem;
    border: 1 / $setFs + rem solid rgba(#fff, .8);
    border-right: 0;
    display: flex;
    align-items: center;
}

.epaper_right {
    flex: 0 0 720 / $setFs + rem;
    border: 1 / $setFs + rem solid rgba(#fff, .8);
    padding: 8 / $setFs + rem 5 / $setFs + rem;

    @media screen and (max-width: 950px) {
        flex: 0 0 550 / $setFs + rem;
    }

    @media screen and (max-width: 480px) {
        flex: 0 0 300 / $setFs + rem;
    }
}

.epaper_form {
    width: 100%;
    display: flex;
    align-items: center;
}

.epaper_title {
    white-space: nowrap;
    font-size: 18 / $setFs + rem;

    @media screen and (max-width: 480px) {
        font-size: 22 / $setFs + rem;
    }
}

.epaper_input {
    flex: 1;
    appearance: none;
    border: none;
    background: transparent;
    color: #fff;
    font-size: 18 / $setFs + rem;
    font-family: $font;
    padding: 2 / $setFs + rem 10 / $setFs + rem;
}

.epaper_submit {
    appearance: none;
    border: none;
    background: transparent;
    box-shadow: none;
    font-size: 20 / $setFs + rem;
    margin: 0;
    color: #fff;
    cursor: pointer;
}

.epaper_error_msg {
    font-size: 15 / $setFs + rem;
    color: rgba(248, 215, 218, 0.7);
    margin-bottom: 7 / $setFs + rem;

    @media screen and (max-width: 480px) {
        text-align: center;
    }
}

.footer_nav_row {
    padding-bottom: 30 / $setFs + rem;

    @media screen and (max-width: 480px) {
        padding-bottom: 0;
    }
}

.footer_nav_list {
    display: flex;
    margin-left: -30 / $setFs + rem;
    margin-bottom: 60 / $setFs + rem;

    @media screen and (max-width: 480px) {
        flex-wrap: wrap;
        margin-left: 0;
        text-align: center;
    }
}

.footer_nav_li {
    font-size: 20 / $setFs + rem;
    font-family: $font1;
    line-height: 2;
    padding: 0 30 / $setFs + rem;

    @media screen and (max-width: 480px) {
        flex: 0 0 50%;
        font-size: 24 / $setFs + rem;
        padding: 5 / $setFs + rem 0;
    }
}

.footer_subnav_li {
    font-size: 18 / $setFs + rem;
    font-family: $font;
    line-height: 2;

    @media screen and (max-width: 480px) {
        font-size: 22 / $setFs + rem;
    }
}