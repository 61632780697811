.loading {
    width: 100%;
    height: 100%;
    background: #1b1b1b;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
}

.loader_container {
    position: relative;
}

.circle_box {
    width: 20 / $setFs + rem;
    height: 80 / $setFs + rem;
    margin: 0 auto;
    position: relative;

    .circle {
        width: 20 / $setFs + rem;
        height: 20 / $setFs + rem;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.3);
        opacity: .3;
        animation: circle 0.5s alternate infinite ease;
    }
}

@keyframes circle {
    0% {
        top: 60 / $setFs + rem;
        height: 5 / $setFs + rem;
        border-radius: 50 / $setFs + rem 50 / $setFs + rem 25 / $setFs + rem 25 / $setFs + rem;
        transform: scaleX(1.7);
    }

    40% {
        height: 20 / $setFs + rem;
        border-radius: 50%;
        transform: scaleX(1);
    }

    100% {
        top: 0%;
    }
}

.shadow {
    width: 20 / $setFs + rem;
    height: 4 / $setFs + rem;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 62 / $setFs + rem;
    left: 0;
    animation: shadow 0.5s alternate infinite ease;
    filter: blur(1 / $setFs + rem);
    z-index: -1;
}

@keyframes shadow {
    0% {
        transform: scaleX(1.5);
    }

    40% {
        transform: scaleX(1);
        opacity: 0.7;
    }

    100% {
        transform: scaleX(0.2);
        opacity: 0.4;
    }
}

.loading_txt {
    text-align: center;
    color: rgba(255, 255, 255, 0.2);
    font-size: 15 / $setFs + rem;
    letter-spacing: 0;
    margin-top: 10 / $setFs + rem;
}