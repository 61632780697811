.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    margin-left: -14 / $setFs + rem;
    margin-right: -14 / $setFs + rem;
    margin-bottom: 30 / $setFs + rem;

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        font-size: 19 / $setFs + rem;
        line-height: 1.5;
        padding: 0 16 / $setFs + rem;
        position: relative;

        &::after {
            content: '>';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(50%, -53%);
            opacity: .2;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }

    a {
        opacity: .7;
        @include acc;

        &:focus,
        &:hover {
            color: $color;
            opacity: 1;
        }
    }
}