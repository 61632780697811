@import "header_hamburger";
@import "header_nav";
@import "header_subnav";
@import "header_func";


.header {
    width: 100%;
    box-shadow: 2 / $setFs + rem 3 / $setFs + rem 5 / $setFs + rem rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;

    // .container {
    //     max-width: none;
    // }

    &.active {
        .header_top {
            background: rgba(255, 255, 255, .9);
        }

        .header_bottom {
            background: rgba($color2, .9);

            @media screen and (min-width: 951px) {
                height: 0 / $setFs + rem;
            }
        }

        // .mainLogo_pc {
        //     height: 28 / $setFs + rem;
        // }

        // .nav_link {
        //     font-size: 17 / $setFs + rem;
        // }

        // .func_link {
        //     font-size: 17 / $setFs + rem;
        // }

        // .header_cate_link {
        //     font-size: 16 / $setFs + rem;

        //     &~.subnav_box {
        //         .header_cate_link {
        //             font-size: 20 / $setFs + rem;
        //         }
        //     }
        // }

        // .icon_text {
        //     width: 20 / $setFs + rem;
        // }
    }
}

.header_top {
    background: rgba(255, 255, 255, 1);
    transition: background .2s ease-out;
    position: relative;
    z-index: 2;

    @media screen and (max-width: 950px) {
        padding: 13 / $setFs + rem 0;
    }

    @media screen and (max-width: 480px) {
        padding: 17 / $setFs + rem 0;
    }
}

.header_bottom {
    background: $color2;
    transition: all .3s ease-out;
    overflow: hidden;

    @media screen and (min-width: 951px) {
        height: 60 / $setFs + rem;
    }

    .button_row {
        justify-content: center;
        margin-top: 0;
    }
}

.header_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header_left {
    display: flex;
    align-items: center;

    @media screen and (max-width: 950px) {
        padding-left: 0;
    }
}

.header_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // padding-right: 40 / $setFs + rem;
    padding-right: 60 / $setFs + rem;

    @media screen and (max-width: 950px) {
        padding-right: 0;
    }
}

.header_fixed {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}

.tfamLogo,
.mainLogo {
    display: none;
    // margin-left: 10 / $setFs + rem;

    @media screen and (max-width: 950px) {
        display: block;
    }

    &.pc {
        display: flex;
        align-items: center;
        // position: fixed;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        margin-top: 1.75rem;
        // margin-left: 100 / $setFs + rem;
        // margin-left: 41 / $setFs + rem;

        @media screen and (max-width: 950px) {
            display: none;
        }
    }
}

.tfamLogo {
    &.pc {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        margin-top: 34 / $setFs + rem;
        margin-left: 41 / $setFs + rem;

        @media screen and (max-width: 950px) {
            display: none;
        }
    }
}

.mainLogo_img {
    width: auto;
    height: 35 / $setFs + rem;
    margin-left: 17 / $setFs + rem;
    transition: height .2s ease-out;

    @media screen and (max-width: 480px) {
        height: 32 / $setFs + rem;
    }
}

.tfamLogo_img {
    width: auto;
    height: 30 / $setFs + rem;

    @media screen and (max-width: 480px) {
        height: 25 / $setFs + rem;
    }
}

.tfamLogo_link,
.mainLogo_link {
    @include acc;
}

.mainLogo_link {
    display: flex;
    align-items: center;
}