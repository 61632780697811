$winSize: 1920;



html {
    width: 100%;
    font-family: $font;
    color: $txtColor;
    font-size: $setFs/$winSize * 100vw;
    // letter-spacing: 1 / $setFs + rem;
    background-image: url('../../img/bg.jpg');
    background-size: 200px auto;
    position: relative;
    overflow-x: hidden;
    animation: bg 1s linear infinite;


    @media screen and (max-width: 1440px) {
        $winSize: 1680;
        font-size: $setFs/$winSize * 100vw;
    }

    @media screen and (max-width: 950px) {
        $winSize: 950;
        font-size: $setFs/$winSize * 100vw;
    }

    @media screen and (max-width: 480px) {
        $winSize: 520;
        font-size: $setFs/$winSize * 100vw;
        background-size: 100px auto;
    }
}

@keyframes bg {
    50% {
        background-position: 800px 600px;
    }

    100% {
        background-position: -600px -400px;
    }
}

body {
    @extend html;
    user-select: none;
}

._en {
    letter-spacing: 0;
}

.wrapper {
    width: 100%;
    line-height: (48 / 34);
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding-top: 90 / $setFs + rem;

    @media screen and (max-width: 950px) {
        padding-top: 4rem;
    }

    @media screen and (max-width: 480px) {
        padding-top: 4.4rem;
    }
}

.container {
    $x: 80 / $setFs + rem;
    width: calc(100% - #{$x});
    max-width: 1360 / $setFs + rem;
    position: relative;
    margin: 0 auto;
    z-index: 1;

    @media screen and (max-width: 950px) {
        $x: 40 / $setFs + rem;
        width: calc(100% - #{$x});
        max-width: 1000 / $setFs + rem;
    }

    // @media screen and (max-width: 480px) {
    //     $x: 20 / $setFs + rem;
    //     width: calc(100% - #{$x});
    //     max-width: 1000 / $setFs + rem;
    // }

}

.container_inner {
    @extend .container;
    width: 100%;
    max-width: 1100 / $setFs + rem;
}

section {
    position: relative;
}