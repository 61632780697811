.lightbox {
    width: 100%;
    height: 100%;
    padding-top: 40 / $setFs + rem;
    padding-bottom: 40 / $setFs + rem;
    box-sizing: border-box;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.97);
    align-items: center;
    justify-content: center;
    display: none;
    opacity: 0;
    z-index: 999;

    @media screen and (min-width: 951px) {
        &::-webkit-scrollbar {
            width: 4 / $setFs + rem;
        }

        &::-webkit-scrollbar-track {
            border-radius: 4 / $setFs + rem;
            background: rgba(255, 255, 255, 0.1);
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.3);
        }
    }

    @media screen and (max-width: 950px) {
        padding-top: 65 / $setFs + rem;
        padding-bottom: 65 / $setFs + rem;
    }
}


.lb_board {
    width: 100%;
    max-width: 1000 / $setFs + rem;
    margin: auto;
    position: relative;
}

.btn_close {
    width: 30 / $setFs + rem;
    height: 30 / $setFs + rem;
    border: 1 / $setFs + rem solid #fff;
    position: fixed;
    top: 24 / $setFs + rem;
    right: 32 / $setFs + rem;

    @media screen and (max-width: 480px) {
        right: 10 / $setFs + rem;
    }

    &:before,
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2 / $setFs + rem;
        border-radius: 3 / $setFs + rem;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -1 / $setFs + rem;
        background: #fff;
        transition: all 0.4s ease-out;
    }

    &:before {
        transform: rotate(-45deg);
    }

    &:after {
        transform: rotate(45deg);
    }

    &:hover {
        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    a {
        height: 100%;
        @include acc;
    }
}

.lb_btn {
    cursor: pointer;
}

#lb_show {
    background: rgba(#fff, .95);
    align-items: flex-start;

    @media screen and (max-width: 480px) {
        align-items: center;
    }

    .btn_close {
        border: 1 / $setFs + rem solid $txtColor;

        &:before,
        &:after {
            background: $txtColor;
        }
    }

    .lb_show_box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: calc(100vh - #{80 / $setFs + rem});
        background: #fff;
        padding: 15 / $setFs + rem;
        padding-top: 20 / $setFs + rem;
        box-sizing: border-box;
        box-shadow: 2 / $setFs + rem 3 / $setFs + rem 7 / $setFs + rem rgba(#000, .25);

        @media screen and (max-width: 480px) {
            min-height: auto;
        }
    }

    .lb_show_img {
        width: auto;
        max-width: 1366 / $setFs + rem;
        margin: 0 auto;

        @media screen and (max-width: 480px) {
            max-width: 100%;
        }
    }

    .lb_show_caption {
        width: 100%;
        color: $txtColor;
        line-height: 1.5;
        padding: 15 / $setFs + rem 30 / $setFs + rem 0;
        box-sizing: border-box;

        @media screen and (max-width: 480px) {
            font-size: 20 / $setFs + rem;
            padding: 15 / $setFs + rem 0 0;
        }
    }

}