.maintitle_row {
    text-align: center;
    font-family: $font1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.dark {
        .maintitle_tw {
            color: #fff;
            font-weight: normal;
        }

        .maintitle_en {
            color: $color;
            text-shadow: 1px 0 0 rgba(255, 255, 255, 0.1), 0 -1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1), -1px 0 0 rgba(255, 255, 255, 0.1);
        }
    }

    &.left {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.maintitle_tw {
    font-size: 48 / $setFs + rem;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 1px;
    margin-top: -25px;
    color: $color;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 950px) {
        line-height: 1.3;
    }

    @media screen and (max-width: 480px) {
        font-size: 44 / $setFs + rem;
        margin-top: -24 / $setFs + rem;
    }
}

.maintitle_en {
    color: #f2f2f2;
    text-transform: uppercase;
    font-size: 100 / $setFs + rem;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0.3), 0 -1px 0 rgba(0, 0, 0, 0.3), 0 1px 0 rgba(0, 0, 0, 0.3), -1px 0 0 rgba(0, 0, 0, 0.3);
    line-height: 1;

    @media screen and (max-width: 480px) {
        font-size: 72 / $setFs + rem;
    }
}

.subtitle {
    font-size: 27 / $setFs + rem;
    margin-top: 17 / $setFs + rem;
}