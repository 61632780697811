.hamburger {
    width: 36 / $setFs + rem;
    height: 36 / $setFs + rem;
    position: relative;
    margin-left: 18 / $setFs + rem;
    transition: all .2s ease-out;
    z-index: 1;
    display: none;

    @media screen and (max-width: 950px) {
        display: block;
    }

    &.pc {
        width: 56 / $setFs + rem;
        height: 56 / $setFs + rem;
        display: block;
        // position: fixed;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999;
        margin-top: 21 / $setFs + rem;
        // margin-right: 30 / $setFs + rem;
        border-radius: 50 / $setFs + rem;
        background: rgba(#fff, .6);

        @media screen and (max-width: 950px) {
            display: none;
        }

        .hamburger_bars {
            width: 32 / $setFs + rem;
            height: 32 / $setFs + rem;
        }

        .hamburger_bar {
            width: 70%;

            &:before,
            &:after {
                width: 100/70*100%;
            }
        }

        &.cross {
            .hamburger_bar {
                width: 100%;

                &:before {
                    width: 110/100*100%;
                    transform: translateY(4px);
                }

                &:after {
                    width: 80/100*100%;
                    transform: translateY(-4px);
                }
            }
        }
    }
}

.hamburger_link {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include acc;
}

.hamburger_bars {
    width: 36 / $setFs + rem;
    height: 36 / $setFs + rem;
    position: relative;
}

.hamburger_bar {
    width: 100%;
    height: 2 / $setFs + rem;
    background: $color;
    border-radius: 4 / $setFs + rem;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -1 / $setFs + rem;
    transition: all .4s ease-out;

    &:before,
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2 / $setFs + rem;
        background: $color;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -1 / $setFs + rem;
        transition: all .4s ease-out;
    }


    &:before {
        transform: translateY(-8px);
    }

    &:after {
        transform: translateY(8px);
    }
}

.cross {
    .hamburger_bar {
        background: transparent;

        &:before {
            transform: translateY(0) rotate(135deg);
        }

        &:after {
            transform: translateY(0) rotate(-135deg);
        }
    }
}