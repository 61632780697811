.nav {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    @media screen and (max-width: 950px) {
        display: block;
        height: calc(100% - 3.9rem);
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 150 / $setFs + rem 0;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        background: rgba(255, 255, 255, 0.99);
    }

    @media screen and (max-width: 480px) {
        padding: 100 / $setFs + rem 0;
    }

    .nav_hide {
        display: none;

        @media screen and (max-width: 950px) {
            display: block;
        }
    }
}

.nav_list {
    display: flex;
    justify-content: center;
    padding: 0 20 / $setFs + rem;

    @media screen and (max-width: 950px) {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 0;
    }
}

.nav_li {
    position: relative;
    display: none;

    &.header_show {
        display: block;

        .nav_li {
            display: block;

            &.hide {
                display: none;
            }
        }
    }

    &.m_show {
        @media screen and (max-width: 950px) {
            display: block;
        }
    }


    @media screen and (min-width: 951px) {

        &:nth-child(5n+1) {
            .nav_link {

                &:hover,
                &:focus {
                    color: $color4;
                }
            }
        }

        &:nth-child(5n+2) {
            .nav_link {

                &:hover,
                &:focus {
                    color: $color3;
                }
            }
        }

        &:nth-child(5n+3) {
            .nav_link {

                &:hover,
                &:focus {
                    color: $color8;
                }
            }
        }

        &:nth-child(5n+4) {
            .nav_link {

                &:hover,
                &:focus {
                    color: $color6;
                }
            }
        }

        &:nth-child(5n+5) {
            .nav_link {

                &:hover,
                &:focus {
                    color: $color7;
                }
            }
        }
    }

    .subnav_box {

        @media screen and (max-width: 950px) {
            box-shadow: none;
        }
    }
}

.nav_link {
    font-size: (20 + 5) / $setFs + rem;
    font-weight: bold;
    font-family: $font1;
    color: #000;
    line-height: 2.5;
    white-space: nowrap;
    padding: 14 / $setFs + rem 20 / $setFs + rem;
    bottom: -2 / $setFs + rem;
    @include acc;

    @media screen and (max-width: 480px) {
        font-size: 2.25rem;
        padding: 10 / $setFs + rem 20 / $setFs + rem;
        bottom: 0;
    }
}