.func_li,
.nav_li {

    &:hover,
    &.show {
        .subnav_box {
            opacity: 1;
            visibility: visible;
            transform: translateX(-50%) translateY(0);

            @media screen and (max-width: 950px) {
                transform: none;
            }
        }
    }
}

.row_block {
    &.show {
        .subnav_box {
            transform: translateY(0);
        }
    }
}

.subnav_box {
    box-shadow: 2 / $setFs + rem 3 / $setFs + rem 5 / $setFs + rem rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-10px);

    @media screen and (min-width: 951px) {
        opacity: 0;
        visibility: hidden;
        transition: all .1s ease-out;
    }

    @media screen and (max-width: 950px) {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
    }

    .nav_list {
        display: block;
        background: #fff;
        padding: 20 / $setFs + rem 0;

        @media screen and (max-width: 950px) {
            background: #f5f5f5;
        }
    }

    .nav_link {
        font-size: (19 + 5) / $setFs + rem;
        font-weight: normal;
        font-family: $font1;
        color: $txtColor;
        line-height: 2.5;
        text-align: center;
        white-space: nowrap;
        padding: 0 50 / $setFs + rem;

        &::before {
            display: none;
        }

        @media screen and (max-width: 950px) {
            // font-size: 22 / $setFs + rem;
            font-family: $font;
            text-align: left;
        }

        @media screen and (max-width: 480px) {
            font-size: 26 / $setFs + rem;
            padding: 0 20 / $setFs + rem;
        }
    }

    .menu_hide {
        display: none;
    }

    .mobile_show {
        @media screen and (max-width: 950px) {
            display: block;
        }
    }
}

// .active {
//     .row_block {

//         &>.subnav_box {
//             top: 120 / $setFs + rem;

//             @media screen and (max-width: 950px) {
//                 top: 6.9rem;
//             }
//         }
//     }
// }

.row_block {

    &>.subnav_box {
        width: 100vw;
        background: rgba(#fff, .95);
        position: fixed;
        // top: 130 / $setFs + rem;
        top: 100%;
        left: 0%;
        transform: translateY(0);

        // @media screen and (max-width: 950px) {
        //     top: 7.05rem;
        // }
    }

    .button_row {
        padding-bottom: 30 / $setFs + rem;
    }
}