.accesskey {
    font-size: 16 / $setFs + rem;
    position: absolute;
    right: 100%;
    top: 0;
    margin-right: 10 / $setFs + rem;
    z-index: 1;
    opacity: 0;
    @include acc;

    @media screen and (max-width: 950px) {
        display: none;
    }

    &:focus {
        opacity: 1;
    }
}

.tpl_left {
    .accesskey {
        color: #fff;
    }
}

.access_content {
    width: 1 / $setFs + rem;
    height: 1 / $setFs + rem;
    font-size: 16 / $setFs + rem;
    padding: 0 10 / $setFs + rem;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 100%;
    z-index: 10000;
    clip: rect(0, 0, 0, 0);
    @include acc;

    @media screen and (max-width: 768px) {
        display: none;
    }

    &:focus {
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        transform: translateY(#{22 / $setFs + rem});
    }
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1 / $setFs + rem;
    margin: -1 / $setFs + rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1 / $setFs + rem;
}

.button_row.fuctionbtn_row {
    margin-left: -5 / $setFs + rem;
}

.social_btn_link {
    width: 36 / $setFs + rem;
    height: 36 / $setFs + rem;
    font-size: 20 / $setFs + rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @include acc;

    &.fb {
        &:hover {
            color: #4267B2;
        }
    }
}

.fontsize_btn {
    width: 36 / $setFs + rem;
    height: 36 / $setFs + rem;
    font-size: 17 / $setFs + rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color2;
    color: $txtColor;
    border: 0;
    box-shadow: 0;
    border-radius: 36 / $setFs + rem;
    position: relative;
    transition: background .2s ease-out;
    cursor: pointer;

    &:focus,
    &:hover {
        background: $txtColor;
        color: #fff;
    }

    .fa-minus,
    .fa-plus {
        font-weight: bold;
        transform: scale(.5);
        position: absolute;
        top: 2 / $setFs + rem;
        right: -2 / $setFs + rem;
    }
}

.pic {
    position: relative;
    overflow: hidden;
}

.img {
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.textarea {
    white-space: pre-line;
}

:focus,
button:focus {
    outline: 0;
}

a {
    display: block;
    text-decoration: none;
    transition: all 0.2s ease-out;
    color: inherit;
    box-sizing: border-box;
    position: relative;

    &:hover {
        color: inherit;
        text-decoration: none;
    }
}


.hide {
    display: none;
}

body .pc {
    @media screen and (max-width: 950px) {
        display: none;
    }
}

body .pad {
    display: none;

    @media screen and (max-width: 950px) {
        display: block;
    }
}

body .mobile {
    display: none;

    @media screen and (max-width: 640px) {
        display: block;
    }
}

.clear {
    &:before {
        content: "";
        display: table;
    }

    &:after {
        @extend :before;
        clear: both;
    }
}

.c {
    clear: both;
}

img {
    width: 100%;
    height: auto;
    display: block;
}

iframe {
    width: 100%;
    height: auto;
}

.list_bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.align_right {
    text-align: right;
}