.button_row {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.button_col {
    padding: 0 5 / $setFs + rem;
}

.button {
    color: #fff;
    font-size: 17 / $setFs + rem;
    background: $txtColor;
    line-height: 50 / $setFs + rem;
    border: 0;
    border-radius: 50 / $setFs + rem;
    transition: background .2s ease-out;

    @media screen and (max-width: 950px) {
        font-size: 20 / $setFs + rem;
        line-height: 55 / $setFs + rem;
    }

    &:hover {
        background: $color7;
    }

    &.white {
        border: 1px solid #fff;
        transition: all 0.2s ease-out;

        &:hover {
            border-color: $color7;
        }
    }
}

.button_link {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    padding: 0 36 / $setFs + rem;
    @include acc;

    @media screen and (max-width: 950px) {
        line-height: 50 / $setFs + rem;
    }
}